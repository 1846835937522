import React, { FC } from 'react';
import { Link } from 'gatsby';
import { DateTime } from 'luxon';
import { Article } from 'superclient';

const EditorialIndexCard: FC<{ article: Article }> = ({ article }) => {
  return (
    <div className="article__card rel">
      {article.featuredImage && (
        <div
          className="article__card-image mb05"
          style={{
            backgroundImage:
              'url(' +
              article.featuredImage.file.url +
              '?fm=jpg&q=80&w=800&fl=progressive)'
          }}
        />
      )}
      <Link className="abs x y top left" to={`/editorial/${article.slug}`} />
      <div className="article__card_contents f jcb fw">
        <h4 className="m0 py05">{article.title}</h4>
        <p className="small">{article.cardPreviewSnippet}</p>
      </div>
      <hr />
      <div className="f fr jcs aic mt05 mb1 pb05">
        <h6 className="caps small m0 p0 x">
          {article.date && (
            <span>{DateTime.fromISO(article.date).toFormat('MMMM d, y')}</span>
          )}
        </h6>
        <h6 className="tag caps p05 bcg m0">{article.type || 'Article'}</h6>
      </div>
    </div>
  );
};

export default React.memo(EditorialIndexCard);
