import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useIntersect } from '../state/use-intersect';
import { Article, ArticleType } from 'superclient';
import MetaModule from '../components/core/meta-module';
import EditorialIndexCard from '../components/editorial/editorial-index-card';
import EditorialNewsletterModule from '../components/editorial/editorial-newsletter-module';
import Carousel from '../components/core/carousel';

const filters: ArticleType[] = Object.values(ArticleType);

const filterDisplayName = (filter: ArticleType): string => {
  switch (filter) {
    case ArticleType.Article:
      return 'Articles';
    case ArticleType.Podcast:
      return 'Podcasts';
    default:
      return filter;
  }
};

const PAGE_LENGTH = 6;

const TemplateIndex: FC<{ pageContext: { articles: Article[] } }> = ({
  pageContext: { articles }
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [imageIndex, setImageIndex] = useState(0);
  const [page, setPage] = useState(1);

  const loadMoreRef = useRef<any>(null);
  const [setNode, entry] = useIntersect();

  useEffect(() => {
    setNode(loadMoreRef.current);
  }, [setNode]);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setPage(page + 1);
    }
  }, [entry, page]);

  const results = useMemo(() => {
    const filtered = selectedFilter
      ? articles.filter((a) => a.type === selectedFilter.toLowerCase())
      : articles;
    return filtered.slice(0, page * PAGE_LENGTH);
  }, [page, selectedFilter, articles]);

  const loadMore = useMemo(() => {
    const filtered = selectedFilter
      ? articles.filter((a) => a.type === selectedFilter.toLowerCase())
      : articles;
    if (filtered.length > results.length) {
      return <div className="caps xsmall ac f1 py1 my1 pointer">Loading...</div>;
    } else {
      return <span />;
    }
  }, [results, articles, page, selectedFilter]);

  const carouselItems = useMemo(() => {
    return articles
      .map((item, i) => {
        return {
          original: `${item.featuredImage.file.url}`,
          thumbnail: '',
          _key: `${item[i]}`,
          title: `${item.title}`,
          productSlug: ''
        };
      })
      .slice(0, 3);
  }, []);

  const updateIndex = (index: number) => {
    setImageIndex(index);
  };

  return (
    <div className="x">
      <MetaModule title="Our Greatest Space Stories" path="/" />
      <Carousel items={carouselItems} onIndexChange={updateIndex} />
      <div className="rel">
        <div className="featured__headlines bcg">
          <div className="featured__headlines_block container--xl ma z1 rel bcg p15">
            {articles.map((article, i) => {
              return (
                <div
                  key={article.id}
                  className={`featured__headlines-single x rel ${
                    imageIndex === i ? 'active' : ''
                  } 
                  `}
                >
                  <Link
                    className="abs x y top left"
                    to={`/editorial/${article.slug}`}
                  />
                  <div className="mt05" />
                  <div className="f jcb fw ais featured__headlines-flex">
                    <div className="x">
                      <h1 className="p0 mb05 mt0 featured__headlines_header">
                        {article.title}
                      </h1>
                      <h6 className="caps xsmall m0 mb05 p0 featured__tags">
                        {article.tags &&
                          article.tags.map((tag) => (
                            <span key={Math.random()}>{tag}</span>
                          ))}
                      </h6>
                    </div>
                    <div className="x">
                      {article.subTitle && (
                        <h6 className="caps mt1 mb1 p0 f aic">
                          {article.subTitle}{' '}
                          <span className="arrow__right arrow ml05 block cb rel" />
                        </h6>
                      )}
                      <p className="s1">{article.cardPreviewSnippet}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Layout after the Carousel */}
      <div className="al">
        <div className="container--xl ma p15">
          <h6 className="caps pb0">Latest Stories</h6>
          <div className="article__filters pb15 f jcc aic caps">
            {filters.map((filter) => (
              <span
                key={filter}
                className={classNames(`m1 small`, {
                  active: selectedFilter === filter
                })}
                onClick={() => {
                  setSelectedFilter(filter);
                  setPage(1);
                }}
              >
                {filterDisplayName(filter)}
              </span>
            ))}
          </div>
          {selectedFilter && (
            <div className="my1 f jcs aic">
              <p className="mr1">
                {`You're viewing all `}
                <em>{selectedFilter}</em>
                {' content'}
              </p>
              <span
                className="article__filters-clear small caps"
                onClick={() => {
                  setSelectedFilter(undefined);
                  setPage(1);
                }}
              >
                Clear Filter
              </span>
            </div>
          )}
          {results.length > 0 ? (
            <div className="f jcb ais fw">
              {results.slice(0, 6).map((article) => (
                <EditorialIndexCard key={article.id} article={article} />
              ))}
            </div>
          ) : (
            <div className="article__filters-empty ac h3 p15 f jcc aic">
              {`We're sorry there's currently nothing posted in ${selectedFilter}`}
            </div>
          )}
        </div>

        {results.length > 6 && (
          <div className="rel x bcg mt1 mb2 py15">
            <div className={'article__filters__newsletter_background'} />
            <div className="container--xl ma p15">
              <EditorialNewsletterModule
                data={{
                  headline: 'Subscribe',
                  tagline: 'Space stories in your inbox, four times a month',
                  cta: 'Sign Up',
                  successMessage: 'We got you. More supercluster headed your way.',
                  placeholder: 'YOURNAME@EMAIL.COM'
                }}
                padding={false}
              />
            </div>
          </div>
        )}

        {results.length > 6 ? (
          <div className="container--xl ma p15">
            <div className="f jcb ais fw">
              {results.slice(6, results.length).map((article) => (
                <EditorialIndexCard key={article.id} article={article} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="x jcs f ais" ref={loadMoreRef}>
        {loadMore}
      </div>
    </div>
  );
};

export default TemplateIndex;
